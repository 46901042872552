<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 474.3 400.39"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M474.13,26.11h-350V74.26h350Z"/><path d="M474.18,176H124.29V224.3H474.18Z"/><path d="M124.3,326.05V374.3h350V326.05Z"/><path d="M.23,325.75H48.64V337.4H24.77v26H48.63v11.48H.23v25.56h74.6V300H.23Z"/><path d="M53.39,149.65c-15.33.14-30.66,0-46,0h-7V176H48.61c-2.06,2.23-3.27,3.58-4.52,4.89Q23.59,202.47,3.11,224C1.9,225.31.25,226.91.18,228.41c-.3,7.26-.13,14.54-.13,21.9H74.74V224.7H28.07l6.52-6.93c12-12.71,24.08-25.37,36-38.17,5.6-6,5.83-14,1.09-20.63A20.94,20.94,0,0,0,53.39,149.65Z"/><path d="M24.63,100.29H48.79V0H.24V25.82H24.63Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconIBAN',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
